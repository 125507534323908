import React, { Component } from 'react';
import types from 'prop-types';
import { styled, StyleSheet } from '@rexlabs/styling';
import { COLORS } from 'theme/index';

export const ICONS = {
  /** NOTE: These icons are serving as an intermediary until replaced with the new icons below. */
  APP_SEARCH: {
    CONTACT: require('assets/icons/nav/contact.svg').default,
    BUILDING: require('assets/icons/app-search/building.svg').default,
    PROJECT: require('assets/icons/app-search/building.svg').default,
    PROJECT_STAGE: require('assets/icons/app-search/building.svg').default,
    INVOICE: require('assets/icons/app-search/invoice.svg').default,
    LISTING: require('assets/icons/nav/listing.svg').default,
    CURRENT_LISTING: require('assets/icons/nav/listing.svg').default,
    ARCHIVED_LISTING: require('assets/icons/nav/listing.svg').default,
    PROPERTY: require('assets/icons/nav/property.svg').default,
    MARKET_LEAD: require('assets/icons/app-search/market-lead.svg').default,
    SEARCH: require('assets/icons/app-search/search.svg').default,
    RECENT: require('assets/icons/app-search/recent.svg').default,
    ARROW: require('assets/icons/app-search/arrow.svg').default,
    CROSS: require('assets/icons/nav/close.svg').default,
    OWNER: require('assets/icons/app-search/rex-my-record.svg').default,
    LOCK: require('assets/icons/app-search/rex-lock.svg').default,
    CAMPAIGN: require('assets/icons/campaign_trimmed.svg')
  },

  SELECT: {
    PROPERTIES: require('assets/icons/property.svg').default,
    LISTINGS: require('assets/icons/listing.svg').default,
    VALUE: {
      TOWN_CITY: require('assets/icons/size-14-14/town_city.svg').default,
      STREET: require('assets/icons/size-14-14/street.svg').default,
      POSTCODE: require('assets/icons/size-14-14/postcode.svg').default,
      POSTCODE_AREA: require('assets/icons/size-14-14/town_city.svg').default,
      POSTCODE_DISTRICT_SECTOR: require('assets/icons/size-14-14/postcode_district_sector.svg')
    },
    OPTION: {
      TOWN_CITY: require('assets/icons/size-19-19/town_city.svg').default,
      STREET: require('assets/icons/size-19-19/street.svg').default,
      POSTCODE: require('assets/icons/size-19-19/postcode.svg').default,
      POSTCODE_AREA: require('assets/icons/size-19-19/town_city.svg').default,
      POSTCODE_DISTRICT_SECTOR: require('assets/icons/size-19-19/postcode_district_sector.svg')
    }
  },

  PAGINATION: {
    CHEVRON_ARROW_LEFT:
      require('assets/icons/pagination/chevron-arrow-left.svg').default,
    ARROW_LEFT: require('assets/icons/pagination/arrow-left.svg')
  },

  /** Use these and below */
  HAMBURGER: require('assets/icons/nav/hamburger.svg').default,
  HAMBURGER_18: require('assets/icons/nav/hamburger-18.svg').default,
  REXFEST_TICKET: require('assets/icons/nav/new/rexfest-ticket.svg').default,
  HOME: require('assets/icons/nav/new/rex-home.svg').default,
  LISTINGS: require('assets/icons/nav/new/listings.svg').default,
  LISTINGS_12: require('assets/icons/listings-12.svg').default,
  PROPERTIES: require('assets/icons/nav/new/properties.svg').default,
  PROPERTIES_12: require('assets/icons/properties-12.svg').default,
  BUILDING: require('assets/icons/nav/new/buildings.svg').default,
  CONTACTS: require('assets/icons/nav/new/contacts.svg').default,
  CONTACTS_12: require('assets/icons/contacts-12.svg').default,
  CALENDAR_12: require('assets/icons/calendar-12.svg').default,
  INSPECTION_12: require('assets/icons/inspection-12.svg').default,
  OAB_12: require('assets/icons/oab-12.svg').default,
  APPRAISAL_13: require('assets/icons/appraisal-13.svg').default,

  FINANCE: require('assets/icons/nav/new/financials.svg').default,
  FINANCIALS_POUND_12: require('assets/icons/financials-pound-12.svg').default,
  FINANCIALS_DOLLAR_12: require('assets/icons/financials-dollar-12.svg')
    .default,
  DOCUMENT_POUND_12: require('assets/icons/document-pound-12.svg').default,
  DOCUMENT_DOLLAR_12: require('assets/icons/document-dollar-12.svg').default,
  DOCUMENT_COG_12: require('assets/icons/document-cog-12.svg').default,
  DOCUMENT_12: require('assets/icons/document-12.svg').default,
  CURRENCY_POUND_12: require('assets/icons/currency-pound-12.svg').default,
  CURRENCY_DOLLAR_12: require('assets/icons/currency-dollar-12.svg').default,
  CIRCLE_POUND_12: require('assets/icons/circle-pound-12.svg').default,
  CIRCLE_DOLLAR_12: require('assets/icons/circle-dollar-12.svg').default,
  INVOICE: require('assets/icons/nav/new/invoices.svg').default,
  INVOICE_12: require('assets/icons/invoice-12.svg').default,
  OTHER: require('assets/icons/nav/new/other-lists.svg').default,
  SETTINGS: require('assets/icons/nav/new/settings.svg').default,
  ADMIN: require('assets/icons/nav/new/admin.svg').default,
  CLOSE: require('assets/icons/nav/close.svg').default,
  BACK: require('assets/icons/nav/close.svg').default,
  DEALS: require('assets/icons/nav/new/deals.svg').default,
  LEADS: require('assets/icons/nav/new/inbox.svg').default,
  LEADS_12: require('assets/icons/inbox-12.svg').default,
  REX_UNIVERSITY: require('assets/icons/nav/new/rex-university.svg').default,
  LOGOUT: require('assets/icons/nav/logout.svg').default,
  RELOAD: require('assets/icons/nav/reload.svg').default,
  ANNOUNCEMENTS: require('assets/icons/nav/announcement.svg').default,
  ADD: require('assets/icons/nav/add.svg').default,
  COMPANY: require('assets/icons/nav/new/businesses.svg').default,
  PHONE: require('assets/icons/nav/new/phone.svg').default,
  EMAIL: require('assets/icons/nav/email.svg').default,
  EMAIL_STRIKETHROUGH: require('assets/icons/email-strikethrough.svg').default,
  LETTER: require('assets/icons/nav/letter.svg').default,
  SMS: require('assets/icons/nav/sms.svg').default,
  NOTE: require('assets/icons/nav/note.svg').default,
  REMINDER: require('assets/icons/nav/reminder-2.svg').default,
  FEEDBACK: require('assets/icons/nav/feedback-1.svg').default,
  HELP: require('assets/icons/nav/help.svg').default,
  HELP_ALT: require('assets/icons/help-2.svg').default,
  APPS: require('assets/icons/nav/new/applications.svg').default,
  SEARCH: require('assets/icons/nav/search.svg').default,
  SEARCH_12: require('assets/icons/search-12.svg').default,
  PRESENT: require('assets/icons/nav/present.svg').default,
  TICK: require('assets/icons/tick.svg').default,
  EMPTY: require('assets/icons/empty.svg').default,
  SELECTED: require('assets/icons/selected.svg').default,
  CHEVRON: require('assets/icons/nav/chevron.svg').default,
  CHEVRON_RIGHT: require('assets/icons/chevron-large-right.svg').default,
  CHEVRON_RIGHT_12: require('assets/icons/chevron-right-12.svg').default,
  CHEVRON_DOWN_SMALL: require('assets/icons/chevron-small-down.svg').default,
  UPLOAD_NEW: require('assets/icons/upload-new.svg').default,
  PROJECT: require('assets/icons/nav/new/projects.svg').default,
  PROJECTS_12: require('assets/icons/projects-12.svg').default,
  PROJECT_STAGE: require('assets/icons/nav/new/projects.svg').default,
  PROGRAMMING: require('assets/icons/programming.svg').default,
  USER: require('assets/icons/user.svg').default,

  // @deprecated
  DOCUMENT: require('assets/icons/document.svg').default,
  VIDEO: require('assets/icons/video-library.svg').default,
  SUPPORT: require('assets/icons/support-status.svg').default,

  DONT_CALL: require('assets/icons/call-dont.svg').default,
  DONT_MAIL: require('assets/icons/mail-dont.svg').default,
  DONT_SMS: require('assets/icons/sms-dont.svg').default,
  DONT_EMAIL: require('assets/icons/email-dont.svg').default,

  WAND: require('assets/icons/wand.svg').default,

  EDIT: require('assets/icons/edit.svg').default,

  ADD_12: require('assets/icons/add-12.svg').default,
  ADD_18: require('assets/icons/add-18.svg').default,
  /**
   * @deprecated use ADD_18
   */
  ADD_MEDIUM: require('assets/icons/add-medium.svg').default,
  ADD_MEDIUM_THICK: require('assets/icons/add-medium-thick.svg').default,

  REMOVE: require('assets/icons/remove.svg').default,

  DOWN: require('assets/icons/chevron-large-down.svg').default,
  ARROW_BACK: require('assets/icons/back-arrow.svg').default,
  ARROW_DOWN: require('assets/icons/arrow-down.svg').default,
  CLOSE_LARGE: require('assets/icons/close-large.svg').default,
  CLOSE_MEDIUM_THIN: require('assets/icons/close-medium-thin.svg').default,
  CLOSE_MEDIUM: require('assets/icons/close-medium.svg').default,
  CLOSE_SMALL: require('assets/icons/close-small.svg').default,

  // Deprecated - this has the extra padding
  PADLOCK: require('assets/icons/lock.svg').default,
  PADLOCK_18: require('assets/icons/padlock_18.svg').default,

  EDIT_CONTENT: require('assets/icons/edit-content.svg').default,
  CALENDAR: require('assets/icons/calendar.svg').default,
  RECENT: require('assets/icons/recent.svg').default,
  OVERLAY_BACK: require('assets/icons/overlay-back.svg').default,

  WARNING: require('assets/icons/warning.svg').default,
  WARNING_FILLED: require('assets/icons/warning-filled.svg').default,
  WARNING_RED: require('assets/icons/warning-red.svg').default,

  INFO: require('assets/icons/info.svg').default,
  INFO_SMALL: require('assets/icons/info-12x12.svg').default,
  MARKET_LEAD: require('assets/icons/nav/new/market-lead.svg').default,

  CAMPAIGN: require('assets/icons/campaign.svg').default,
  PERMALINK: require('assets/icons/permalink.svg').default,
  DOWNLOAD: require('assets/icons/download.svg').default,
  DOWNLOAD_SMALL: require('assets/icons/download-small.svg').default,
  DRAG_HANDLE: require('assets/icons/drag-handle.svg').default,

  // Workflow Widget Icons
  // TODO: Rename these to be more specific
  ACTIVE: require('assets/icons/active.svg').default,
  CHECK: require('assets/icons/check.svg').default,
  PAUSED: require('assets/icons/paused.svg').default,
  FAILED: require('assets/icons/failed.svg').default,

  // Group App Icons
  GROUP_APP_LOGO: require('assets/icons/group-app-logo.svg').default,
  GROUP_APP_EMAIL: require('assets/icons/email.svg').default,
  GROUP_APP_DOWNLOAD: require('assets/icons/download-new.svg').default,

  // Login
  REX_LOGO: require('assets/icons/rex-logo.svg').default,
  REX_PM_LOGO: require('assets/icons/rex-pm-logo.svg').default,
  POCKET_LOGO: require('assets/icons/pocket.svg').default,
  CLOUD: require('assets/icons/cloud.svg').default,
  PAPER_PLANE: require('assets/icons/paper-plane.svg').default,
  EYE: require('assets/icons/eye.svg').default,
  EYE_CROSSED: require('assets/icons/eye-crossed.svg').default,

  // Unsupported browser
  FIREFOX: require('assets/icons/firefox.svg').default,
  SAFARI: require('assets/icons/safari.svg').default,
  EDGE: require('assets/icons/edge.svg').default,
  CHROME: require('assets/icons/chrome.svg').default,

  // @deprecated
  TEMPLATE: require('assets/icons/template.svg').default,
  REFERRAL: require('assets/icons/referral.svg').default,

  FILTER: require('assets/icons/filter.svg').default,
  COLUMN: require('assets/icons/column.svg').default,
  ARROW_LEFT: require('assets/icons/arrow-left.svg').default,
  ARROW_RIGHT: require('assets/icons/arrow-right.svg').default,
  ACTION_MENU: require('assets/icons/action-menu.svg').default,
  ARROW_UP: require('assets/icons/arrow-upwards.svg').default,

  // Dashboard icons
  DASHBOARDS: {
    TRIANGLE_DOWN: require('assets/icons/dashboards/triangle-bottom.svg')
      .default,
    ARROW_FORWARD: require('assets/icons/dashboards/arrow-forward.svg').default,
    ARROW_BACKWARD: require('assets/icons/dashboards/arrow-backward.svg')
      .default,
    NEGATIVE_CHANGE: require('assets/icons/dashboards/negative-change.svg')
      .default,
    POSITIVE_CHANGE: require('assets/icons/dashboards/positive-change.svg')
      .default,
    NO_CHANGE: require('assets/icons/dashboards/no-change.svg').default,
    SECTION_SEPARATOR: require('assets/icons/dashboards/vertical-seperator.svg')
      .default,
    INFO_TOOLTIP: require('assets/icons/dashboards/i.svg').default,
    CALENDAR: require('assets/icons/dashboards/calendar.svg').default,
    FILTER: require('assets/icons/dashboards/filter.svg').default,
    SIGNBOARD: require('assets/icons/dashboards/signboard.svg').default,
    USERS: require('assets/icons/dashboards/users.svg').default,
    COMPANY_LOCATION: require('assets/icons/dashboards/company-location.svg')
      .default,
    COLUMN: require('assets/icons/dashboards/column.svg')
  },

  // TEMP: Currently exporting as an svg isn't working correctly, so we are using a png in it's place until that can be sorted out
  ROSIE: require('assets/icons/rosie.png').default,

  // Added during the icon cleanup, moved from shell
  CRM_2_PRINT_LOGO: require('assets/icons/crm-2-print-logo.svg').default,
  APPRAISAL: require('assets/icons/appraisal.svg').default,

  ADD_DROPDOWN: require('assets/icons/add-dropdown.svg').default,
  API: require('assets/icons/api.svg').default,
  ARCHIVED: require('assets/icons/archived.svg').default,
  ARROW_DOWN_HOLLOW: require('assets/icons/arrow-down-hollow.svg').default,
  AUCTION: require('assets/icons/auction.svg').default,
  AUTOMATIC_UPDATE: require('assets/icons/automatic-updates.svg').default,
  BRIEFCASE: require('assets/icons/briefcase.svg').default,
  CALENDAR_MENU: require('assets/icons/calendar-menu.svg').default,
  CALENDAR_CALENDAR: require('assets/icons/calendar-calendar.svg').default,
  CALENDAR_ALERTS: require('assets/icons/calendar-alerts.svg').default,
  CALENDAR_DESCRIPTION: require('assets/icons/calendar-description.svg')
    .default,
  CALENDAR_ORGANISER: require('assets/icons/calendar-organiser.svg').default,
  CALENDAR_GUESTS: require('assets/icons/calendar-guests.svg').default,
  CALENDAR_LOCATION: require('assets/icons/calendar-location.svg').default,
  CALENDAR_TIME: require('assets/icons/calendar-time.svg').default,
  CALENDAR_TIME_ZONE: require('assets/icons/calendar-time-zone.svg').default,
  CANCELLED: require('assets/icons/cancelled.svg').default,
  CHAIN_LINK: require('assets/icons/chain-link.svg').default,
  CIRCLE_FULL: require('assets/icons/system-search-full.svg').default,
  CIRCLE_HOLLOW: require('assets/icons/circle-hollow.svg').default,
  // @deprecated
  EXTERNAL_LINK_DEPRECATED: require('assets/icons/external-link-deprecated.svg')
    .default,
  EXTERNAL_LINK: require('assets/icons/external-link.svg').default,
  E_MARKETING: require('assets/icons/e-marketing.svg').default,
  GPS_LOGO: require('assets/icons/gps-logo.svg').default,
  NEW_SEARCH: require('assets/icons/search.svg').default,
  NO_UPLOAD: require('assets/icons/portal_uploads_disabled.svg').default,
  NO_AUTOMATIC_UPDATE: require('assets/icons/auto_updates_disabled.svg')
    .default,
  NO_E_MARKETING: require('assets/icons/stocklist_emarketing_disabled.svg')
    .default,
  NO_API: require('assets/icons/api_disabled.svg').default,
  OPEN_HOME: require('assets/icons/open-home.svg').default,
  RECUR: require('assets/icons/recur.svg').default,
  UPLOAD: require('assets/icons/upload.svg').default,
  // @deprecated
  USER_GROUP: require('assets/icons/user-group.svg').default,
  USER_GROUP_18: require('assets/icons/user-group-18.svg').default,
  CATASTROPHIC_ERROR:
    require('assets/icons/catastrophic-error-illustration.svg').default,
  RELATED_RECORD: require('assets/icons/related-record.svg').default,

  REX_UNIVERSITY_LOADING:
    require('assets/icons/rex-university-loading-illustration.svg').default,
  REX_UNIVERSITY_ERROR:
    require('assets/icons/rex-university-error-illustration.svg').default,
  SCHEDULED_MESSAGE: require('assets/icons/scheduled-message.svg').default,
  MESSAGE: require('assets/icons/message.svg').default,
  SPARKLES: require('assets/icons/sparkles.svg').default,
  SPARKLE: require('assets/icons/sparkle.svg').default,
  GRAPH: require('assets/icons/dashboard-nav.svg').default,
  GRAPH_SMALL: require('assets/icons/graph-bar.svg').default,
  AGENT_VENDOR: require('assets/icons/reminder-agent-vendor.svg').default,
  FOLLOW_UP: require('assets/icons/follow-up.svg').default,
  ALERT: require('assets/icons/alert.svg').default,
  ALERT_SMALL: require('assets/icons/alert-12x12.svg').default,
  STREAM_NOTE: require('assets/icons/note-stream.svg').default,
  MOBILE: require('assets/icons/note-mobile.svg').default,
  MEETING_NOTE: require('assets/icons/note-meeting.svg').default,
  LETTER_NOTE: require('assets/icons/note-letter.svg').default,
  PHONE_SMALL: require('assets/icons/phone-small.svg').default,
  CHAINS: require('assets/icons/chain.svg').default,
  UNCONFIRMED_APPOINTMENT: require('assets/icons/unconfirmed.svg').default,
  PINNED: require('assets/icons/pinned.svg').default,
  PIN: require('assets/icons/pin.svg').default,

  REPORT_NAV: require('assets/icons/nav/report.svg').default,
  REPORT_15: require('assets/icons/report-15.svg').default,
  REPORT_LARGE: require('assets/icons/report-large.svg').default,
  VPA_12: require('assets/icons/contacts-12.svg').default,
  KEY_12: require('assets/icons/key-12.svg').default,
  ROWS_12: require('assets/icons/rows-12.svg').default,

  STAR_15: require('assets/icons/star-15.svg').default,
  STAR_18: require('assets/icons/star-18.svg').default,
  STAR_OUTLINE_18: require('assets/icons/star-outlined-18.svg').default,

  ROUND_CHECK: require('assets/icons/round-check.svg').default,
  STEP_CHECK: require('assets/icons/step-check.svg').default,

  PRIVACY: {
    CROSS: require('assets/icons/privacy/cross.svg')
  },

  MIN_MAX: require('assets/icons/min-max-input.svg').default,
  SEND_EMAIL: require('assets/icons/send-email.svg').default,
  REINZ_LOGO: require('assets/icons/reinz-logo.svg').default,
  REFRESH: require('assets/icons/refresh.svg').default,
  ROUND_CHECK_BIG: require('assets/icons/round-check-big.svg').default,
  ROUND_CROSS_BIG: require('assets/icons/round-cross-big.svg').default,

  TRACKS: {
    PHONE: require('assets/icons/nav/new/phone.svg').default,
    EMAIL: require('assets/icons/nav/email.svg').default,
    LETTER: require('assets/icons/reminder/letter.svg').default,
    SMS: require('assets/icons/reminder/sms.svg').default,
    TASK: require('assets/icons/reminder/task.svg').default,
    INTERNAL_APPROVAL: require('assets/icons/reminder/internal-approval.svg')
      .default,
    REQUEST_SENT: require('assets/icons/reminder/request-sent.svg').default,
    APPROVED_REMINDER: require('assets/icons/reminder/approved-reminder.svg')
      .default,
    REJECTED_REMINDER: require('assets/icons/reminder/rejected-reminder.svg')
      .default,
    TEMPLATE_BADGE: require('assets/icons/reminder/template-badge.svg')
  },

  CIRCLE_LOCK: require('assets/icons/circle-lock.svg')
};

/**
 * NOTE: These have been mapped to the BE status_id values so we can show the correct Icon.
 *
 * Here is the current list of values pulled from:
 *  SystemValues list "workflow_instance_statuses"
 *
 * ["id" => "running", "text" => "Running"],
 * ["id" => "awaiting_input", "text" => "Awaiting Input"],
 * ["id" => "finished", "text" => "Finished"],
 * ["id" => "error", "text" => "Error"]
 *
 * Extra (not in the SystemValues list): cancelled, active, completed
 */
export const mapWorkflowStatus = {
  active: 'ACTIVE',
  running: 'ACTIVE',
  finished: 'CHECK',
  completed: 'CHECK',
  awaiting_input: 'PAUSED',
  cancelled: 'FAILED',
  error: 'FAILED',
  failed: 'FAILED',
  terminated: 'FAILED'
};

const defaultStyles = StyleSheet({
  icon: {},

  iconControlledColor: {
    '& *:not([fill="none"])': {
      fill: 'currentColor'
    }
  },

  container: {},

  circle: {
    padding: '1rem',
    background: COLORS.COOL_GREY,
    borderRadius: '50%'
  }
});

@styled(defaultStyles)
class Icon extends Component {
  static propTypes = {
    type: types.func.isRequired,
    inCircle: types.bool,
    hasControlledColor: types.bool
  };

  static defaultProps = {
    hasControlledColor: true
  };

  render() {
    const {
      type: Type,
      inCircle,
      styles: s,
      width,
      height,
      hasControlledColor,
      'data-styletrace': dataStyletrace,
      'data-styletarget': dataStyletarget,
      'data-es-keys': dataEsKeys,
      ...rest
    } = this.props;

    return (
      <div
        {...{
          'data-styletrace': dataStyletrace,
          'data-styletarget': dataStyletarget,
          'data-es-keys': dataEsKeys,
          ...rest
        }}
        {...s.with('container', { circle: inCircle })(rest)}
      >
        {Type && (
          <Type
            {...s('icon', { iconControlledColor: hasControlledColor })}
            {...(width ? { width } : {})}
            {...(height ? { height } : {})}
          />
        )}
      </div>
    );
  }
}

export default Icon;
